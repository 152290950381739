import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private componentMethodCallSource = new Subject<void>();

  // Observable stream
  // componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  constructor() { }

   // Service message commands
  //  callComponentMethod() {
  //   this.componentMethodCallSource.next();
  // }

}
