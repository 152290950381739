import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'product-edit',
  templateUrl: './product-edit.component.html'
})
export class ProductEditComponent implements OnInit {

  CATALOG_PRIMARY_ATTR: any = [];
  CATALOG_PACKING_ATTR: any = [];
  CATALOG_ADDITIONAL_ATTR: any = [];

  poStatus = false;
  showButton = true;
  patnerProduct = false;
  formData: any;
  constructor(private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<ProductEditComponent>, @Inject(MAT_DIALOG_DATA) public data, private platformLocation: PlatformLocation) {
    this.platformLocation.onPopState(() => this.dialogRef.close());
  }

  ngOnInit() {
    if (this.data != undefined) {
      this.formData = this.data.data[0];
      this.poStatus = this.data.data[1];
      this.showButton = this.data.showButton;
      this.patnerProduct = this.data.patnerProduct;
    }
    if (this.showButton) {
      this.CATALOG_PRIMARY_ATTR =
        [
          { "Sr": 1, "Attributes": 'CATALOG.COLUMN.DEPARTMENT', "value": { value: 'Produce', component: 'label', field: 'department', disabled: true } },
          { "Sr": 2, "Attributes": 'CATALOG.COLUMN.COMMODITY', "value": { value: 'Fruits', component: 'label', field: 'commodity', disabled: true } },
          { "Sr": 3, "Attributes": 'CATALOG.COLUMN.PACK_TYPE', "value": { value: 'Cases', component: 'dropdwon', field: 'packType', disabled: true } },
          { "Sr": 4, "Attributes": 'CATALOG.COLUMN.PRICE_PER', "value": { value: 'Pack type', component: 'dropdwon', field: 'priceByUom', disabled: true } },
          { "Sr": 5, "Attributes": 'CATALOG.COLUMN.NET_WEIGHT', "value": { value: '12', component: 'radio', field: 'netWeight', uomfield: 'netWeightUom', disabled: this.poStatus ? true : false } },
          { "Sr": 6, "Attributes": 'CATALOG.COLUMN.GROSS_WEIGHT', "value": { value: '12', component: 'radio', field: 'grossWeight', uomfield: 'weightUom', disabled: this.poStatus ? true : false } },
          { "Sr": 7, "Attributes": 'CATALOG.COLUMN.GROWING_METHOD', "value": { value: 'CONVENTIONAL', component: 'dropdwon', field: 'growingMethod', disabled: true } },
          { "Sr": 8, "Attributes": 'CATALOG.COLUMN.GROWING_REGION', "value": { value: '', component: 'dropdwon', field: 'growingRegion', disabled: true } },
          { "Sr": 9, "Attributes": "Bioengineered", "value": { value: '', component: 'only_radio', field: 'bioengineered', disabled: true } },
          { "Sr": 10, "Attributes": 'CATALOG.COLUMN.COUNTRY_OF_ORIGIN', "value": { value: 'USA', component: 'dropdwon', field: 'coo', disabled: this.poStatus ? true : false } },
          { "Sr": 11, "Attributes": 'CATALOG.COLUMN.DEFAULT_PRICE', "value": { value: '', component: 'label', field: 'basePrice', disabled: true } }
        ]
    } else {
      this.CATALOG_PRIMARY_ATTR =
        [
          { "Sr": 1, "Attributes": 'CATALOG.COLUMN.DEPARTMENT', "value": { value: 'Produce', component: 'label', field: 'department', disabled: true } },
          { "Sr": 2, "Attributes": 'CATALOG.COLUMN.COMMODITY', "value": { value: 'Fruits', component: 'label', field: 'commodity', disabled: true } },
          { "Sr": 3, "Attributes": 'CATALOG.COLUMN.PACK_TYPE', "value": { value: 'Cases', component: 'dropdwon', field: 'packType', disabled: true } },
          { "Sr": 4, "Attributes": 'CATALOG.COLUMN.PRICE_PER', "value": { value: 'Pack type', component: 'dropdwon', field: 'priceByUom', disabled: true } },
          { "Sr": 5, "Attributes": 'CATALOG.COLUMN.NET_WEIGHT', "value": { value: '12', component: 'radio', field: 'netWeight', uomfield: 'netWeightUom', disabled: this.poStatus ? true : false } },
          { "Sr": 6, "Attributes": 'CATALOG.COLUMN.GROSS_WEIGHT', "value": { value: '12', component: 'radio', field: 'grossWeight', uomfield: 'weightUom', disabled: this.poStatus ? true : false } },
          { "Sr": 7, "Attributes": 'CATALOG.COLUMN.GROWING_METHOD', "value": { value: 'CONVENTIONAL', component: 'dropdwon', field: 'growingMethod', disabled: true } },

          { "Sr": 8, "Attributes": "Bioengineered", "value": { value: '', component: 'only_radio', field: 'bioengineered', disabled: true } },
          { "Sr": 9, "Attributes": 'CATALOG.COLUMN.COUNTRY_OF_ORIGIN', "value": { value: 'USA', component: 'dropdwon', field: 'coo', disabled: this.poStatus ? true : false } },
          { "Sr": 10, "Attributes": 'CATALOG.COLUMN.DEFAULT_PRICE', "value": { value: '', component: 'label', field: 'basePrice', disabled: true } }
        ]

    }
    this.CATALOG_PACKING_ATTR =
      [
        // { "Sr": 1, "Attributes": 'CATALOG.COLUMN.UOM', "value": { value: '', component: 'radio', field: 'dimensionUom', type: '' } },
        { "Sr": 1, "Attributes": 'CATALOG.COLUMN.TIE', "value": { value: '', component: 'input', field: 'ti', type: 'digit' } },
        { "Sr": 2, "Attributes": 'CATALOG.COLUMN.HIGH', "value": { value: '', component: 'input', field: 'hi', type: 'digit' } },
        { "Sr": 3, "Attributes": 'CATALOG.COLUMN.PALLET', "value": { value: '', component: 'input', field: 'pallet', type: 'digit' } },
        { "Sr": 4, "Attributes": 'CATALOG.COLUMN.UOM', "value": { value: '', component: 'radio', field: 'dimensionUom', type: '' } },
        { "Sr": 5, "Attributes": 'CATALOG.COLUMN.LENGTH', "value": { value: '', component: 'input', field: 'length', type: 'decimal' } },
        { "Sr": 6, "Attributes": 'CATALOG.COLUMN.WIDTH', "value": { value: '', component: 'input', field: 'width', type: 'decimal' } },
        { "Sr": 7, "Attributes": 'CATALOG.COLUMN.HEIGHT', "value": { value: '', component: 'input', field: 'height', type: 'decimal' } },
        { "Sr": 8, "Attributes": 'CATALOG.COLUMN.VOLUME', "value": { value: '', component: 'volume', field: 'volume', type: 'decimal' } }
      ];
    this.CATALOG_ADDITIONAL_ATTR =
      [
        { "Sr": 1, "Attributes": 'CATALOG.LABEL.BRAND', "value": { value: '', component: 'input', field: 'brand', type: 'alphaNum' } },
        { "Sr": 2, "Attributes": 'CATALOG.LABEL.VARIETY', "value": { value: '', component: 'input', field: 'variety', type: 'alphaNum' } },
        { "Sr": 3, "Attributes": 'CATALOG.LABEL.PACK_SIZE_UOM', "value": { value: '', component: 'input', field: 'packDesc', type: 'alphaNum' } },
        { "Sr": 4, "Attributes": 'CATALOG.LABEL.PACK_COUNT', "value": { value: '', component: 'input', field: 'packCount', type: 'digit3' } },
        { "Sr": 5, "Attributes": 'CATALOG.LABEL.GRADE', "value": { value: '', component: 'input', field: 'grade', type: 'alphaNum' } },
        { "Sr": 6, "Attributes": 'CATALOG.LABEL.PLU_CODE', "value": { value: '', component: 'input', field: 'pluCode', type: 'digit' } },
        { "Sr": 7, "Attributes": 'CATALOG.LABEL.PARTNER_SKU', "value": { value: '', component: 'input', field: 'tpSKU', type: 'alphaNumWithLen32' } }
      ];
      if (this.patnerProduct ||  this.formData.get('tpProductId').value == null) {
        this.CATALOG_ADDITIONAL_ATTR.push({ "Sr": 8, "Attributes": 'CATALOG.LABEL.SHELF_LIFE', "value": { value: '', component: 'input', field: 'shelfLife', type: 'digit' } });       //(PM) PMB-9282-Add Shelf Life field to Buyer Product attributes in the catalog
        this.CATALOG_ADDITIONAL_ATTR.push({ "Sr": 9, "Attributes": 'CATALOG.LABEL.SAFETY_STOCK', "value": { value: '', component: 'input', field: 'safetyStock', type: 'digit' } }); 
        this.CATALOG_ADDITIONAL_ATTR.push({ "Sr": 10, "Attributes": 'CATALOG.LABEL.ORDER_RESTRICTION_TYPE', "value": { value: 'WAREHOUSE TIE', component: 'text', field: 'orderRestrictionType' } });
      }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  productDetailSubmitEvent(event) {
    this.dialogRef.close({ action: true, data: event });
  }

  productDetailCloseEvent(event) {
    this.dialogRef.close({ action: event, data: null });
  }

}
